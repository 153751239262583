.App {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

#banner-clip{
	clip-path: polygon(100% 0%, 0% 0% , 0% 76.67%, 1% 76.69%, 2% 76.77%, 3% 76.90%, 4% 77.07%, 5% 77.30%, 6% 77.57%, 7% 77.89%, 8% 78.26%, 9% 78.66%, 10% 79.11%, 11% 79.60%, 12% 80.12%, 13% 80.68%, 14% 81.26%, 15% 81.88%, 16% 82.52%, 17% 83.18%, 18% 83.85%, 19% 84.55%, 20% 85.25%, 21% 85.96%, 22% 86.67%, 23% 87.39%, 24% 88.10%, 25% 88.80%, 26% 89.49%, 27% 90.17%, 28% 90.83%, 29% 91.47%, 30% 92.08%, 31% 92.67%, 32% 93.22%, 33% 93.74%, 34% 94.23%, 35% 94.68%, 36% 95.08%, 37% 95.45%, 38% 95.77%, 39% 96.04%, 40% 96.26%, 41% 96.44%, 42% 96.57%, 43% 96.64%, 44% 96.67%, 45% 96.64%, 46% 96.56%, 47% 96.44%, 48% 96.26%, 49% 96.03%, 50% 95.76%, 51% 95.44%, 52% 95.07%, 53% 94.66%, 54% 94.21%, 55% 93.73%, 56% 93.20%, 57% 92.65%, 58% 92.06%, 59% 91.44%, 60% 90.81%, 61% 90.14%, 62% 89.47%, 63% 88.77%, 64% 88.07%, 65% 87.36%, 66% 86.65%, 67% 85.93%, 68% 85.22%, 69% 84.52%, 70% 83.83%, 71% 83.15%, 72% 82.49%, 73% 81.86%, 74% 81.24%, 75% 80.66%, 76% 80.10%, 77% 79.58%, 78% 79.09%, 79% 78.65%, 80% 78.24%, 81% 77.88%, 82% 77.56%, 83% 77.29%, 84% 77.06%, 85% 76.89%, 86% 76.76%, 87% 76.69%, 88% 76.67%, 89% 76.69%, 90% 76.77%, 91% 76.90%, 92% 77.08%, 93% 77.31%, 94% 77.58%, 95% 77.90%, 96% 78.27%, 97% 78.68%, 98% 79.13%, 99% 79.62%, 100% 80.14%);

}

/* Banner Right Animation */
@keyframes floating {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

#baner-right{
  animation: floating 5s ease-in-out infinite;
}

/* .left-banner-input-box{
  background-color: rgba(255, 255, 255, 0.24);
} */


/* Tab design  */
.react-tabs__tab-list {
	border-bottom: 1px solid #ffc108 !important;
}

.react-tabs__tab--selected {
    background: #ffc108!important;
    border-color: #ffc108 !important;
    color: white !important;
    border-radius: 0;
	border: none;
}

/* Active scroll design  */

.active-scroll{
	border-bottom: 1px solid #ffc108;
}

 @media screen and (max-width:992px){
		.regular{
			display: none!important;
		}
 }

 @media screen and (min-width:992px){
		.locale{
			display: none!important;
		}
 }

 .c-border-none{
	border: none !important;
}

.or-box{
	position: relative;

}

.or{
	position: absolute;
	top: -15px;
	left: 40%;
	background-color: #ffc108!important;
	color: black;
	padding:0px 20px;
	
}

 

@media screen and (max-width:768px){
	.h-img-box {
		margin-top: 0px!important;
	}
}
.bg-primary,.btn-primary{
	background: #ffc108!important;
	border-color: #ffc108!important;
}

 .text-primary {
	color:#ffc108!important;
}

.border-t-primary{
	border-top-color:#ffc108!important;
}
.border-primary{
	border-color: #ffc108!important;
}
 
.service:hover{
	background-color: #ffc108!important;
	color:black!important;
}
.hub-name{
	color:black!important;
}

.input-primary,textarea, input{
	border-color: #ffc108!important;
}

.input-primary:focus,textarea:focus, input:focus {
    outline: 2px solid  #ffc108!important;
}
.bg-orange-50 svg{
	color:#ffc108!important
}

label.login-btn.active:focus .login-a{
	background:none!important;
}
.topbanner{
	padding-top: 100px!important;
}

 @media screen and (max-width: 768px){
	  
	input.input-1{
		width: 150px!important;
	}
	input.input-2{
		width: 200px!important;
	}

	.or-box{
		 width: 100%!important;
	}

	.normal-case.text-3xl{
		width: 100px!important;
	}
}  