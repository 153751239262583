@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
:root {
    --cd-color-1: hsl(206, 21%, 24%);
    --cd-color-1-h: 206;
    --cd-color-1-s: 21%;
    --cd-color-1-l: 24%;
    --cd-color-2: hsl(205, 38%, 89%);
    --cd-color-2-h: 205;
    --cd-color-2-s: 38%;
    --cd-color-2-l: 89%;
    --cd-color-3: hsl(207, 10%, 55%);
    --cd-color-3-h: 207;
    --cd-color-3-s: 10%;
    --cd-color-3-l: 55%;
    --cd-color-4: hsl(111, 51%, 60%);
    --cd-color-4-h: 111;
    --cd-color-4-s: 51%;
    --cd-color-4-l: 60%;
    --cd-color-5: hsl(47, 85%, 61%);
    --cd-color-5-h: 356;
    --cd-color-5-s: 53%;
    --cd-color-5-l: 49%;
    --cd-color-6: hsl(47, 85%, 61%);
    --cd-color-6-h: 47;
    --cd-color-6-s: 85%;
    --cd-color-6-l: 61%;
    --cd-header-height: 200px;
    --font-primary: 'Droid Serif', serif;
    --font-secondary: 'Open Sans', sans-serif;
}

:root {
    --form-control-padding-x: var(--space-sm);
    --form-control-padding-y: var(--space-xs);
    --form-control-radius: 0.25em;
}


:root {
    --btn-font-size: 1em;
    --btn-font-size-sm: calc(var(--btn-font-size) - 0.2em);
    --btn-font-size-md: calc(var(--btn-font-size) + 0.2em);
    --btn-font-size-lg: calc(var(--btn-font-size) + 0.4em);
    --btn-radius: 0.25em;
    --btn-padding-x: var(--space-sm);
    --btn-padding-y: var(--space-xs);
}


@media (min-width: 64rem){
    @supports (--css: variables){

        :root {
            --text-base-size: 1.25em;
            --text-scale-ratio: 1.25;
        }
    }
}

:root {
    --font-primary: sans-serif;
    --text-base-size: 1em;
    --text-scale-ratio: 1.2;
    --text-xs: calc(1em/var(--text-scale-ratio)/var(--text-scale-ratio));
    --text-sm: calc(var(--text-xs)*var(--text-scale-ratio));
    --text-md: calc(var(--text-sm)*var(--text-scale-ratio)*var(--text-scale-ratio));
    --text-lg: calc(var(--text-md)*var(--text-scale-ratio));
    --text-xl: calc(var(--text-lg)*var(--text-scale-ratio));
    --text-xxl: calc(var(--text-xl)*var(--text-scale-ratio));
    --text-xxxl: calc(var(--text-xxl)*var(--text-scale-ratio));
    --body-line-height: 1.4;
    --heading-line-height: 1.2;
    --font-primary-capital-letter: 1;
}


:root {
    --radius: 0.25em;
}


:root, [data-theme="default"] {
    --color-primary-darker: hsl(220, 90%, 36%);
    --color-primary-darker-h: 220;
    --color-primary-darker-s: 90%;
    --color-primary-darker-l: 36%;
    --color-primary-dark: hsl(220, 90%, 46%);
    --color-primary-dark-h: 220;
    --color-primary-dark-s: 90%;
    --color-primary-dark-l: 46%;
    --color-primary: hsl(220, 90%, 56%);
    --color-primary-h: 220;
    --color-primary-s: 90%;
    --color-primary-l: 56%;
    --color-primary-light: hsl(220, 90%, 66%);
    --color-primary-light-h: 220;
    --color-primary-light-s: 90%;
    --color-primary-light-l: 66%;
    --color-primary-lighter: hsl(220, 90%, 76%);
    --color-primary-lighter-h: 220;
    --color-primary-lighter-s: 90%;
    --color-primary-lighter-l: 76%;
    --color-accent-darker: hsl(355, 90%, 41%);
    --color-accent-darker-h: 355;
    --color-accent-darker-s: 90%;
    --color-accent-darker-l: 41%;
    --color-accent-dark: hsl(355, 90%, 51%);
    --color-accent-dark-h: 355;
    --color-accent-dark-s: 90%;
    --color-accent-dark-l: 51%;
    --color-accent: hsl(355, 90%, 61%);
    --color-accent-h: 355;
    --color-accent-s: 90%;
    --color-accent-l: 61%;
    --color-accent-light: hsl(355, 90%, 71%);
    --color-accent-light-h: 355;
    --color-accent-light-s: 90%;
    --color-accent-light-l: 71%;
    --color-accent-lighter: hsl(355, 90%, 81%);
    --color-accent-lighter-h: 355;
    --color-accent-lighter-s: 90%;
    --color-accent-lighter-l: 81%;
    --color-black: hsl(240, 8%, 12%);
    --color-black-h: 240;
    --color-black-s: 8%;
    --color-black-l: 12%;
    --color-white: hsl(0, 0%, 100%);
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-success-darker: hsl(94, 48%, 36%);
    --color-success-darker-h: 94; 
}

:root {
    --icon-xxs: 12px;
    --icon-xs: 16px;
    --icon-sm: 24px;
    --icon-md: 32px;
    --icon-lg: 48px;
    --icon-xl: 64px;
    --icon-xxl: 128px;
}

:root {
    --zindex-header: 2;
    --zindex-popover: 5;
    --zindex-fixed-element: 10;
    --zindex-overlay: 15;
}

:root {
    --radius-sm: calc(var(--radius, 0.25em)/2);
    --radius-md: var(--radius, 0.25em);
    --radius-lg: calc(var(--radius, 0.25em)*2);
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, .085), 0 1px 8px rgba(0, 0, 0, .1);
    --shadow-md: 0 1px 8px rgba(0, 0, 0, .1), 0 8px 24px rgba(0, 0, 0, .15);
    --shadow-lg: 0 1px 8px rgba(0, 0, 0, .1), 0 16px 48px rgba(0, 0, 0, .1), 0 24px 60px rgba(0, 0, 0, .1);
    --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
}

:root {
    --max-width-xxs: 32rem;
    --max-width-xs: 38rem;
    --max-width-sm: 48rem;
    --max-width-md: 64rem;
    --max-width-lg: 80rem;
    --max-width-xl: 90rem;
    --max-width-xxl: 120rem;
}

:root {
    --space-unit: 1em;
    --space-xxxxs: calc(0.125*var(--space-unit));
    --space-xxxs: calc(0.25*var(--space-unit));
    --space-xxs: calc(0.375*var(--space-unit));
    --space-xs: calc(0.5*var(--space-unit));
    --space-sm: calc(0.75*var(--space-unit));
    --space-md: calc(1.25*var(--space-unit));
    --space-lg: calc(2*var(--space-unit));
    --space-xl: calc(3.25*var(--space-unit));
    --space-xxl: calc(5.25*var(--space-unit));
    --space-xxxl: calc(8.5*var(--space-unit));
    --space-xxxxl: calc(13.75*var(--space-unit));
    --component-padding: var(--space-md);
}

.cd-timeline {
    overflow: hidden;
    padding: 2em 0;
    padding: var(--space-lg) 0;
    color: hsl(207, 10%, 55%);
    color: var(--cd-color-3); 
    
    font-family: 'Droid Serif', serif;
    font-family: var(--font-primary);
}

.cd-timeline__container {
    position: relative!important;
    padding: 1.25em 0;
    padding: var(--space-md) 0!important;
}

.max-width-lg {
    max-width: 80rem;
    max-width: var(--max-width-lg);
}

.cd-timeline__container {
    position: relative;
    padding: 1.25em 0;
    padding: var(--space-md) 0;
}

.max-width-lg {
    max-width: 80rem;
    max-width: var(--max-width-lg);
}

.cd-timeline .container {
    width: calc(100% - 1.25em);
    width: calc(100% - 2*var(--component-padding));
    margin-left: auto;
    margin-right: auto;
}

.cd-timeline__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: hsl(205, 38%, 89%);
    background: var(--cd-color-2);
}


@media (min-width: 64rem){
    .cd-timeline__container::before {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}


.cd-timeline__block {
    display: -ms-flexbox;
    /* display: flex; */
    position: relative;
    z-index: 1;
    margin-bottom: 2em;
    margin-bottom: var(--space-lg);
}


.cd-timeline__img--picture {
    background-color: hsl(111, 51%, 60%);
    background-color: var(--cd-color-4);
}

@media (min-width: 64rem){
    .cd-timeline__img {
        width: 60px;
        height: 60px;
        -ms-flex-order: 1;
        order: 1;
        margin-left: calc(5% - 30px);
        will-change: transform;
    }
}

.cd-timeline__img {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px hsl(0deg 0% 100%), inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
    box-shadow: 0 0 0 4px var(--color-white),inset 0 2px 0 rgba(0,0,0,0.08),0 3px 0 4px rgba(0,0,0,0.05);
}

.cd-timeline__img img {
    width: 24px;
    height: 24px;
}

img, video, svg {
    max-width: 100%;
}

@media (min-width: 64rem){
    .cd-timeline__content {
        width: 45%;
        -ms-flex-positive: 0;
        flex-grow: 0;
        will-change: transform;
        margin: 0;
        font-size: 0.8em;
        --line-height-multiplier: 1.2;
    }
}

.cd-timeline__content h2 {
    color: white;
}

.cd-timeline__content {
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    margin-left: 1.25em;
    margin-left: var(--space-md);
    background: hsl(0, 0%, 100%);
    background: var(--color-white);
    border-radius: 0.25em;
    border-radius: var(--radius-md);
    padding: 1.25em;
    padding: var(--space-md);
    /* box-shadow: 0 3px 0 hsl(205deg 38% 89%);
    box-shadow: 0 3px 0 var(--cd-color-2); */
}

.text-component {
    --component-body-line-height: calc(var(--body-line-height)*var(--line-height-multiplier, 1));
    --component-heading-line-height: calc(var(--heading-line-height)*var(--line-height-multiplier, 1));
}

@media (min-width: 64rem){
    .cd-timeline__block:nth-child(odd) .cd-timeline__content::before {
        right: auto;
        left: 100%;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-left-color: hsl(0, 0%, 100%);
        border-left-color: #ffc108!important;
    }
}


@media (min-width: 64rem){
    .cd-timeline__content::before {
        top: 24px;
    }
}

.cd-timeline__content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-right-color: hsl(0, 0%, 100%);
    border-right-color: var(--color-white);
}

@media (min-width: 64rem){
    .cd-timeline__date {
        position: absolute;
        width: 100%;
        left: 115%;
        top: 10px;
    }
}


.cd-timeline__date {
    color: hsla(207, 10%, 55%, 0.7);
    color: hsla(var(--cd-color-3-h), var(--cd-color-3-s), var(--cd-color-3-l), 0.7);
}
.cd-timeline__img.cd-timeline__img--picture{
    display: inline-block;
    position: absolute;
    left: 46%;
    text-align: center;
    color: white;
}

.cd-timeline__content.text-component.shadow-xl.rounded-lg{
    background-color: #ffc108!important;
    color: black!important;
}
 
.cd-timeline__img.cd-timeline__img--picture{
    font-size: 28px;
}


.right.cd-timeline__block:nth-child(odd) .cd-timeline__content::before{
    left: auto;
    right: 100%;
    border-right-color: #ffc108!important;
    border-left: none;
   
}
 
.right.cd-timeline__content{
    /* float:right; */
}
.right.cd-timeline__date{
    right: 55%;
    top: 10px;
    left: unset;
}
.float-right{
    float:right;
}
.float-left{
    float:left;
}

.right.cd-timeline__block {
    overflow: hidden;
}

.bg-warning{
    background-color: var(--cd-color-6)!important;
}

.container.max-width-lg.cd-timeline__container{
    padding:0px!important;
    width: 50%!important;
}

.notfound{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.mt-4.p-4.rounded-lg.left-banner-input-box.pb-7.min-h-xs{
    width: 80%!important;
}

.cd-timeline__content h2{
    color:white!important;
}

.text-orange-500{
    color:#ffc108!important;
}

.text-2xl.text-primary.title{
    color:black!important;
}